import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserAuth } from "../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toasts
import styles from "./reg.module.css";
import bgimg from "../static/img/compibg2.png";

const Reg = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";

  const [registering, setRegistering] = useState(false);
  const { compiName } = useParams();
  const { user } = UserAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    compi: compiName,
    name: user?.displayName,
    email: user?.email,
    google_id: user?.uid,
    phoneno: "",
    city: "",
    country: "",
    gender: "",
    zonals: "",
    pincode: "",
    address: "",
    instiname: "",
    instiadress: "",
    instipincode: "",
    yearofstudy: "",
    // sexy_word: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!formData.phoneno.match(/^\d{10}$/)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return false;
    }
    if (!formData.pincode.match(/^\d{6}$/)) {
      toast.error("Please enter a valid 6-digit pincode.");
      return false;
    }
    if (!formData.instipincode.match(/^\d{6}$/)) {
      toast.error("Please enter a valid 6-digit institute pincode.");
      return false;
    }
    // for (const [key, value] of Object.entries(formData)) {
    //   if (!value && key !== "zonals") {
    //     toast.error(`Please fill in the ${key} field.`);
    //     return false;
    //   }
    // }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setRegistering(true);
    const sexyWord = `${formData.compi}15Nov${formData.email}or19Nov${formData.phoneno}`;
    const updatedFormData = { ...formData, sexy_word: sexyWord };
    axios
      .post("https://techfest.org/api/compi_reg/", updatedFormData)
      .then((response) => {
        toast.success("Added Successfully!!");
        navigate(`/competitions/${compiName}`);
      })
      .catch((error) => {
        console.error("Error:", error);
        setRegistering(false);
        toast.error("Failed to register. Please try again.");
      });
  };

  const fixbg = {
    backgroundImage: `url(${bgimg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    position: "fixed",
    width: "100%",
    zIndex: "-1",
    top: "0",
    backgroundAttachment: "fixed",
  };

  return (
    <div className={styles.compiReg}>
      <ToastContainer /> {/* Toast container for displaying toasts */}
      <div style={fixbg} />
      <div className={styles.bgitis}>
        <div className={styles.overlay}>
          <div className={styles.reg_head}>
            <div>
              <img src={user?.photoURL} alt="User" />
            </div>
            <div className={styles.head_n}>
              <div>
                <h2>{user?.displayName}</h2>
              </div>
              <hr />
              <div>Every Field is Compulsory</div>
            </div>
          </div>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className={styles.regFormContainer}>
              <div
                className={styles.inputWrapper}
                style={{ display: "none" }}
              >
                <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={user.displayName}
                  onChange={handleChange}
                  className={styles.formInput}
                  disabled
                  autoComplete="off"
                />
              </div>
              <div className={`row ${styles.multiWrapper}`}>
                <div className={`col-md-6 ${styles.inputWrapper}`}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    className={styles.formInput}
                    disabled
                    autoComplete="off"
                  />
                </div>
                <div className={`col-md-6 ${styles.inputWrapper}`}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    Phone No.
                  </label>
                  <input
                    type="text"
                    name="phoneno"
                    value={formData.phoneno}
                    onChange={handleChange}
                    className={styles.formInput}
                    autoComplete="off"
                    maxLength={10}
                    required
                  />
                </div>
              </div>
              <div className={`row ${styles.multiWrapper}`}>
                <div className={`col-md-6 ${styles.inputWrapper}`}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className={styles.formInput}
                    required
                  >
                    <option
                      value=""
                      style={{ display: "none" }}
                      className={styles.genderOps}
                    >
                      Select
                    </option>
                    <option value="M" className={styles.genderOps}>
                      Male
                    </option>
                    <option value="F" className={styles.genderOps}>
                      Female
                    </option>
                    <option value="O" className={styles.genderOps}>
                      Other
                    </option>
                  </select>
                </div>
                <div className={`col-md-6 ${styles.inputWrapper}`}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className={styles.formInput}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className={`row ${styles.multiWrapper}`}>
                <div className={`col-md-6 ${styles.inputWrapper}`}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    Pincode
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    className={styles.formInput}
                    required
                    autoComplete="off"
                    maxLength={6}
                  />
                </div>
                <div className={`col-md-6 ${styles.inputWrapper}`}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    Country
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className={styles.formInput}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              {compiName === "xyz" ? (
                <div className={styles.inputWrapper}>
                  <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                    Nearby Zonal
                  </label>
                  <select
                    name="zonals"
                    value={formData.zonals}
                    onChange={handleChange}
                    className={styles.formInput}
                    required
                  >
                    <option
                      value=""
                      style={{ display: "none" }}
                      className={styles.genderOps}
                    >
                      Nearby Zonals
                    </option>
                    {/* <option value="1" className={styles.genderOps}>
                      Mumbai
                    </option>
                    <option value="2" className={styles.genderOps}>
                      Bhopal
                    </option> */}
                    {/* <option value="3" className={styles.genderOps}>
                      Nagpur
                    </option>
                    <option value="4" className={styles.genderOps}>
                      Hyderabad
                    </option> */}
                    <option value="5" className={styles.genderOps}>
                      Bangalore
                    </option>
                    {/* <option value="6" className={styles.genderOps}>
                      Jaipur
                    </option> */}
                    <option value="9" className={styles.genderOps}>
                      Chandigarh
                    </option>
                    <option value="7" className={styles.genderOps}>
                      Dhaka Bangladesh
                    </option>
                    <option value="8" className={styles.genderOps}>
                      Nepal
                    </option>
                  </select>
                </div>
              ) : (
                <div></div>
              )}
              <div className={styles.inputWrapper}>
                <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className={styles.formInput}
                  autoComplete="off"
                  required
                />
              </div>
              <div className={styles.inputWrapper}>
                <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                  Institute Name
                </label>
                <input
                  type="text"
                  name="instiname"
                  value={formData.instiname}
                  onChange={handleChange}
                  className={styles.formInput}
                  required
                  autoComplete="off"
                />
              </div>
              <div className={styles.inputWrapper}>
                <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                  Institute Address
                </label>
                <input
                  type="text"
                  name="instiadress"
                  value={formData.instiadress}
                  onChange={handleChange}
                  className={styles.formInput}
                  autoComplete="off"
                  required
                />
              </div>
              <div className={styles.inputWrapper}>
                <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                  Institute Pincode
                </label>
                <input
                  type="text"
                  name="instipincode"
                  value={formData.instipincode}
                  required
                  onChange={handleChange}
                  className={styles.formInput}
                  autoComplete="off"
                  maxLength={6}
                />
              </div>
              <div className={styles.inputWrapper}>
                <label className={`${styles.floatingLabel} ${styles.formLabel}`}>
                  Year of Study
                </label>
                <select
                  name="yearofstudy"
                  value={formData.yearofstudy}
                  onChange={handleChange}
                  className={styles.formInput}
                  required
                >
                  <option
                    value=""
                    style={{ display: "none" }}
                    className={styles.genderOps}
                  >
                    Select year of Study
                  </option>
                  <option value="1" className={styles.genderOps}>
                    1st Year
                  </option>
                  <option value="2" className={styles.genderOps}>
                    2nd Year
                  </option>
                  <option value="3" className={styles.genderOps}>
                    3rd Year
                  </option>
                  <option value="4" className={styles.genderOps}>
                    4th Year
                  </option>
                  <option value="5" className={styles.genderOps}>
                    5th Year
                  </option>
                  <option value="16" className={styles.genderOps}>
                    6th Standard
                  </option>
                  <option value="17" className={styles.genderOps}>
                    7th Standard
                  </option>
                  <option value="18" className={styles.genderOps}>
                    8th Standard
                  </option>
                  <option value="19" className={styles.genderOps}>
                    9th Standard
                  </option>
                  <option value="20" className={styles.genderOps}>
                    10th Standard
                  </option>
                  <option value="21" className={styles.genderOps}>
                    11th Standard
                  </option>
                  <option value="22" className={styles.genderOps}>
                    12th Standard
                  </option>
                  <option value="23" className={styles.genderOps}>
                    Graduated
                  </option>
                </select>
              </div>
              <div className="idcbro" style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                {registering ? (
                  <button type="submit" className={styles.formSubmit} disabled>
                    Submitting...
                  </button>
                ) : (
                  <button type="submit" className={styles.formSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reg;
