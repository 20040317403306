import React, { useState, useEffect } from "react";
import styles from "./compi_data.module.css";
// import Faq from "react-faq-component";
import "./x.css";
import Whatsapplink from "../../../../components/whatsapp/link";

const AUV = () => {
  const [activeCity, setActiveCity] = useState("About");
  const [activeIndex, setActiveIndex] = useState(null);
  const openCity = (cityName) => {
    setActiveCity(cityName);
  };

  const handleClick = (index) => {
    if (activeIndex === index) {
      // If the clicked item is already open, close it
      setActiveIndex(null);
    } else {
      // If the clicked item is not open, open it and close any previously open item
      setActiveIndex(index);
    }
  };

  const styles1 = {
    bgColor: "none",
    titleTextColor: "white",
    rowTitleColor: "white",
    arrowColor: "white",
    rowContentColor: "white",
    marginBottom: "20px",
    maxWidth: "80%",
    margin: "auto",
    rowContentMarginBottom: "0",
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const lol = {
    objectFit: "scale-down",
    paddingRight: "8px",
  };
  const walink1 = "https://chat.whatsapp.com/GP3RsNbVqyd4cHTGSyhOhE";
  const dslink1 = "https://chat.whatsapp.com/GP3RsNbVqyd4cHTGSyhOhE";

  const gridStyle = windowWidth > 600 ? { display: 'grid', gridTemplateColumns: '20% 80%', gap: '20px' } : { display: 'flex', flexDirection: 'column', gap: '20px' };

  return (
    <div style={{padding:'24px'}}>
      <div style={{marginTop: '50px'}}></div>
      <div style={gridStyle}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'12px'}} >
          <a href='https://postimages.org/' target='_blank'><img src='https://i.postimg.cc/jS7yNMqC/auv.jpg' border='0' alt='auv'style={{ width: '100%', height: 'auto' }}/></a>
          <h5>
            <div style={{color: "white", fontWeight:'bold', fontSize:'32px'}}> PRIZE INR 2,00,000 </div>
          </h5>
          <div className={styles.grouplink}>
          <div className={styles.wabutton}><Whatsapplink walink={walink1} dslink={dslink1} target="_blank" /></div>
          </div>
          <a href="https://drive.google.com/file/d/1vVJFjJ8rieELdNFvDXV9aErBu9ZqotfZ/view?usp=sharing" style={{color: 'inherit'}}>
            <h5>
              <div style={{color: '#00ffcc',border: '1px solid #00ffcc', padding: '10px', borderRadius: '5px', textAlign: 'center'}}>Problem Statement</div>  
            </h5>
          </a>
        </div>
        <div>
          <div className={styles.info}>
          </div>
          <div className={styles.info_data}>
            {/* <div></div> */}
            <h3>International AUV Challenge</h3>
            <h5 style={{fontWeight: 'bold'}}>Description</h5>
          <h5>The competition requires teams to design and build autonomous underwater vehicles (AUVs) that can successfully navigate a complex underwater maze within a specified time limit. The maze will be filled with obstacles and challenges that will test the AUV's ability to perceive its environment, make informed decisions, and execute precise maneuvers. Once the AUV successfully completes the maze, it must accurately deploy a marker at a designated location. The competition will evaluate teams based on factors such as speed, accuracy, and the overall performance of their AUVs in navigating the underwater maze.</h5>
          <br />
            <h5 style={{ fontWeight: 'bold' }}>Timeline</h5>
            <h5>
              <li>Last date for registration:15th December, 2024</li>
              
            </h5>
                    

            <h5>
              <li>Practice Sessions / Qualifying Round: 16th December, 2024 - 17th December, 2024</li>
            </h5>

            <h5>
              <li>Main Competition: 18th-19th December 2024 </li>
            </h5>
            
          </div>
        </div>

      </div>
      <div className='Contactbox'>
            <div className="Contactbox1">
            Manish<br></br>
              manishsingh.techfest@gmail.com<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${6395842507}`)}>
                {6395842507}
              </div>
            </div>
            <div className="Contactbox1">
              Jay Rathod<br></br>
              jayrathod.techfest@gmail.com<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
                {9399952997}
              </div>
            </div>
          </div>
    </div>
  );
};

export default AUV;
