import React,{useState,useEffect} from 'react';
import styles from './exhi.module.css';
import bg from './assets/Exhi_back.jpeg';
import WorldMap from "./map";
import MapClick from "./m-map";
// import Footer from "../../components/Footer/Footer";
import OurReach from './ourreach';
import exhibitions from './assets/exhibitions.png'
// import cardlec from './assets/lectures_card1.1.png'
import card from './assets/Exhi_card.png'

import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpg'
import img3 from './assets/img3.png'
import img4 from './assets/mindaffect.jpg'
import img5 from './assets/img5.png'
import img6 from './assets/img6.webp'
import img7 from './assets/img7.png'
import img8 from './assets/img8.jpg'
import img9 from './assets/img9.png'
import img10 from './assets/img10.webp'
import img11 from './assets/img11.png'
import img12 from './assets/img12.png'
import img13 from './assets/drdo13.jpg'
import img14 from './assets/img14.jpg'
import img15 from './assets/img15.webp'

const Exhi = () => {
    const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    // console.log(window.innerWidth)
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        // backgroundColor:'#e3e3ed',
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
    };

    const handleRegisterClick = () => {
        window.open("https://docs.google.com/forms/d/1smw4D_ZrBQX8Xs5Yx3DknXBFHexG9f_oEk2ZJeKJOnE", "_blank");
    };

    return (
        <div>
            <div className={styles.exhibitions} style={bgHaiJi}>
                
            <div className={styles.form}>
                <p>Explore cutting edge technology and innovation: Register your school or coaching for Techfest Exhibitions now!</p>
                <p>Exhibitions is free and open for everyone. Pre-registration is not required for Individuals.</p>
                <p>This form is only for school/coaching heads.</p>
                <p>Exhibitions Timing: 10AM to 4PM</p>
                <button onClick={handleRegisterClick} className={styles.customButton}>Register</button>
            </div>
            <br></br>
            <div className={styles.heading}>
            <img src={exhibitions} style={{marginLeft:'10px',marginTop: '260px'}}></img>
            </div>
                <div className={styles.exca}>
                    {/* 1 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img className = {styles.image} src={img1} alt="" />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Unitree G1</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>China</h4>
                        <p className={styles.parainfo}>Advanced humanoid robot for dynamic tasks and human interaction</p>
                        </div>
                    </div>
                    {/* 2 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img className= {styles.image} src={img2} alt="" />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>WEHEAD</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>USA</h4>
                        <p className={styles.parainfo}>Portable head device enabling immersive 3D telepresence experience</p>
                        </div> 
                    </div>
                    {/* 3 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img3} alt="" className={styles.image}/>
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>iMediSync</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>South Korea</h4>
                        <p className={styles.parainfo}>EEG-based device for brain health monitoring and diagnostic application</p>
                        </div>
                    </div>
                    {/* 4 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img12} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>DJRobotics</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>Japan</h4>
                        <p className={styles.parainfo}>Interactive humanoid DJ robot delivering music and engaging performances</p>
                        </div> 
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img4} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>MindAffect</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>Netherlands</h4>
                        <p className={styles.parainfo}>Brain-computer interface enabling communication through brain signals</p>
                        </div>
                    </div>
                    {/* 5 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img5} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Artiligents (Temi Robots)</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>Hong Kong</h4>
                        <p className={styles.parainfo}>Autonomous personal robots with voice assistant and AI capability</p>
                        </div> 
                    </div>
                    {/* 15 */}
                    {/* <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img15} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>ISRO</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>India's space agency, driving satellite launches, space exploration, and innovation</p>
                        </div> 
                    </div> */}
                    {/* 6 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img6} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Eagle robot lab</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>Humanoid robots designed for education and practical robotics training</p>
                        </div> 
                    </div>
                    {/* 7 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img7} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Cader Technologies</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>USA</h4>
                        <p className={styles.parainfo}>AI-powered VisionPro Eyeglass to enhance mobility and independence for the visually impaired</p>
                        </div>
                    </div>
                    {/* 8 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img8} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Earthsense</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>USA</h4>
                        <p className={styles.parainfo}>Autonomous agricultural robots supporting sustainable farming practices</p>
                        </div> 
                    </div>
                    {/* 9 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img9} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Dhruva Space</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>Full-stack space engineering solutions for satellites, launches, and ground support</p>
                        </div> 
                    </div>
                    {/* 10 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img10} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>NSG Commandos</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>Elite counter-terrorism unit focused on combating threats and ensuring national security</p>
                        </div> 
                    </div>
                    {/* 11 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img11} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Kody labs</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>Next-gen smart surveillance robot with advanced monitoring for enhanced protection.</p>
                        </div> 
                    </div>
                    {/* 12 */}
                    {/* 13 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img13} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>DRDO</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>Premier organization for military research, specializing in the development of cutting-edge indigenous defense systems and technologies to strengthen national security</p>
                        </div>
                    </div>
                    {/* 14 */}
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={img14} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Bharat Forge Limited</h4>
                        <h4 className={styles.country} style={{fontSize: '15px'}}>India</h4>
                        <p className={styles.parainfo}>A global engineering leader, manufacturing advanced components and systems for defense, automotive, aerospace, and industrial applications</p>
                        </div> 
                    </div>
                </div>
            <div style={{marginTop:'150px'}}>{isMobile ? <MapClick /> : <WorldMap />}</div>
            <div style={{marginTop:'40px'}}><OurReach/></div>
            <div className={styles.Contactbox}>   
            <div className="Contactbox1">
              Tarun Kumar<br></br>
              tarun@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {7549952736}
              </div>
            </div>
            <div className="Contactbox1">
              Utkarsh Jain<br></br>
              utkarshjain@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {6264477005}
              </div>
            </div>
            <div className="Contactbox1">
              Aleem Rayeen<br></br>
              aleem@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
                {7068987053}
              </div>
            </div>
          
                    
                </div>
                
            </div>
            
            {/* <Footer/> */}
        </div>
    )
}

export default Exhi;