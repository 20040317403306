// import React, { useState } from "react";
// import QrReader from "react-qr-scanner";
// import styles from "./scanner.module.css";

// const Scanner = () => {
//   const [scanResult, setScanResult] = useState("");
//   const [status, setStatus] = useState("Waiting for scan...");

//   const handleScan = (data) => {
//     if (data) {
//       const result = data.text || data; // Support for text property in data
//       setScanResult(result);
//       setStatus("Approved");
//     }
//   };

//   const handleError = (err) => {
//     console.error(err);
//     setStatus(
//       "Error: Unable to access the camera. Please check your permissions or try another device."
//     );
//   };

//   const previewStyle = {
//     height: 240,
//     width: 320,
//   };

//   const retryScan = () => {
//     setStatus("Waiting for scan...");
//     setScanResult("");
//   };

//   return (
//     <div className={styles.scannerContainer}>
//       <h1>QR Code Scanner</h1>
//       <p className={styles.status}>{status}</p>
//       <div className={styles.qrContainer}>
//         <QrReader
//           delay={300} // Scans every 300ms
//           style={previewStyle}
//           onError={handleError}
//           onScan={handleScan}
//         />
//       </div>
//       {scanResult && (
//         <div className={styles.result}>
//           <h2>Scanned Details</h2>
//           <p>{scanResult}</p>
//         </div>
//       )}
//       <button onClick={retryScan} className={styles.retryButton}>
//         Retry
//       </button>
//     </div>
//   );
// };

// export default Scanner;

import React, { useState, useEffect } from "react";
import QrReader from "react-qr-scanner";
import styles from "./scanner.module.css";
import axios from "axios";

const Scanner = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";

  const [scanResult, setScanResult] = useState("");
  const [status, setStatus] = useState("Waiting for scan...");
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle the scanned data
  const handleScan = async (data) => {
    if (data) {
      const result = data.text || data;
      setScanResult(result);
      setStatus("Approved");

      console.log("Scanned result:", result);

      const parts = result.split("\n").map((item) => item.trim());

      const emailPart = parts[0]?.split(":")[1]?.trim();
      const passTypePart = parts[1]?.split(":")[1]?.trim();
      const eventPart = parts[2]?.split(":")[1]?.trim();

      console.log("Parsed email:", emailPart);
      console.log("Parsed passType:", passTypePart);
      console.log("Parsed event:", eventPart);

      if (emailPart && passTypePart && eventPart) {
        try {
          setIsLoading(true);
          const response = await axios.post("https://techfest.org/api/save-scan/", {
            email: emailPart,
            passType: passTypePart,
            event: eventPart,
          });

          if (response.status === 200) {
            setStatus("Data saved successfully!");
            console.log("Data saved successfully!");
          } else {
            setStatus("Error saving data.");
            console.log("Error saving data:", response.data);
          }
        } catch (error) {
          setStatus("Error: Unable to save scanned data.");
          console.error("Error saving scan data:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setStatus("Invalid QR Code format.");
        console.log("Invalid QR Code format! Missing email, passType, or event.");
      }
    }
  };

  // Function to handle scan errors
  const handleError = (err) => {
    console.error("Scan error:", err);
    setStatus("Error: Unable to access the camera. Check permissions or try another device.");
  };

  // Retry scan
  const retryScan = () => {
    setStatus("Waiting for scan...");
    setScanResult("");
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <div className={styles.scannerContainer}>
      <h1>QR Code Scanner</h1>
      <p className={styles.status}>{status}</p>
      <div className={styles.qrContainer}>
        <QrReader
          delay={300} // Scans every 300ms
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          constraints={{
            audio: false,
            video: { facingMode: "environment" },
          }}
        />
      </div>
      {scanResult && (
        <div className={styles.result}>
          <h2>Scanned Details</h2>
          <p>{scanResult}</p>
        </div>
      )}
      {isLoading ? (
        <p>Saving data...</p>
      ) : (
        <button onClick={retryScan} className={styles.retryButton}>
          Retry
        </button>
      )}
    </div>
  );
};

export default Scanner;







