import React from "react";
import styles from "./sponsor.module.css";

import oiii from "./assets/oiii.jpg";
import one1 from "./assets/one1.png";
import one2 from "./assets/one2.png";
import one3 from "./assets/one3.png";
import one4 from "./assets/one4.png";

import two2 from "./assets/two2.png";

import three0 from "./assets/three0.png";
import three1 from "./assets/three1.png";
import three2 from "./assets/three2.png";
import three3 from "./assets/three3.png";
import three4 from "./assets/three4.png";
import three5 from "./assets/three5.png";
import three6 from "./assets/three6.png";
import three7 from "./assets/three7.png";
import three8 from "./assets/three8.png";
import three9 from "./assets/three9.png";
import three10 from "./assets/three10.png";
import three11 from "./assets/three11.png";
import three12 from "./assets/three12.png";

import four1 from "./assets/four1.png";
import four2 from "./assets/four2.png";
import four3 from "./assets/four3.png";
import four5 from "./assets/four5.png";

import five1 from "./assets/five1.png";
import five2 from "./assets/five2.png";
import five4 from "./assets/five4.png";
import five5 from "./assets/five5.png";
import five6 from "./assets/five6.png";

import six1 from "./assets/six1.png";
import six2 from "./assets/six2.png";
import six3 from "./assets/six3.png";
import six4 from "./assets/six4.png";
import six5 from "./assets/six5.png";
import six6 from "./assets/six6.png";
import six7 from "./assets/six7.png";
import six8 from "./assets/six8.png";
import six10 from "./assets/six10.png";

import seven1 from "./assets/seven1.png";
import seven2 from "./assets/seven2.png";
import seven3 from "./assets/seven3.png";
import seven4 from "./assets/seven4.png";
import seven5 from "./assets/seven5.png";
import seven6 from "./assets/seven6.png";
import seven7 from "./assets/seven7.png";
import seven8 from "./assets/seven8.png";

import eight1 from "./assets/eight1.png";
import eight2 from "./assets/eight2.png";
import eight3 from "./assets/eight3.png";
import eight4 from "./assets/eight4.png";
import eight5 from "./assets/eight5.png";
import eight6 from "./assets/eight6.png";
import eight7 from "./assets/eight7.png";
import eight8 from "./assets/eight8.png";
import eight9 from "./assets/eight9.png";

const Sponsor = () => {
  return (
    <div className={styles.body}>
      {/* one */}
      <div className={styles.one}>
        <div className={styles.titleSponsor}>
          <div className={styles.header}>
            <h1>Title Sponsor</h1>
            <div className={styles.line0}></div>
          </div>
          <a href="https://www.marshmclennan.com/">
            <img src={one1} alt="mmc" />
          </a>
        </div>
        <div className={styles.CotitleSponsor}>
          <div className={styles.header}>
            <h1>Co-Title Sponsor</h1>
            <div className={styles.line0}></div>
          </div>
          <a href="https://www.onlinesbi.sbi/">
            <img src={one2} alt="sbi" />
          </a>
        </div>
        <div className={styles.onebox}>
          <div className={styles.content1}>
            <div className={styles.header}>
              <h1>Associate Title Sponsor</h1>
              <div className={styles.line0}></div>
            </div>
            <a href="https://www.npci.org.in/">
              <img src={one3} alt="npci" />
            </a>
          </div>
          <div className={styles.content1}>
            <div className={styles.header}>
              <h1>Powered By</h1>
              <div className={styles.line0}></div>
            </div>
            <a href="https://www.idfcfirstbank.com/">
              <img src={one4} alt="idfc" />
            </a>
          </div>
        </div>
      </div>

      {/* two */}
      <div className={styles.two}>
        <div className={styles.header}>
          <h2>Platinum Sponsors</h2>
          <div className={styles.line0}></div>
        </div>
        <div className={styles.twobox}>
          <div className={styles.content2}>
            <a href="https://www.qualcomm.com/">
              <img src={three6} alt="qualcomm" id={styles.qualcomm2} />
            </a>
            <h5>Qualcomm</h5>
          </div>
          <div className={styles.content2}>
            <a href="https://www.binance.com/en">
              <img src={two2} alt="binance" id={styles.qualcomm2} />
            </a>
            <h5>Binance</h5>
          </div>
        </div>
        <div className={styles.twobox}>
          <div className={styles.content2}>
            <div className={styles.header}>
              <h2>Gold Sponsor</h2>
              <div className={styles.line0}></div>
            </div>
            <a href="https://blix.in/">
              <img src={three4} alt="blix" id={styles.blix2} />
            </a>
            <h5>Blix</h5>
          </div>
          <div className={styles.content2}>
            <div className={styles.header}>
              <h2>Silver Sponsor</h2>
              <div className={styles.line0}></div>
            </div>
            <a href="https://www.datamatics.com/">
              <img src={three5} alt="datamatics" id={styles.datamatics2} />
            </a>
            <h5>Datamatics</h5>
          </div>
        </div>
      </div>

      {/* three */}
      <div className={styles.three}>
        <div className={styles.header}>
          <h1>Competitions</h1>
          <div className={styles.line0}></div>
        </div>
        <div className={styles.threebox}>
          <div className={styles.content3}>
            <h3>Special Competitions Partner</h3>
            <a href="https://edgegroup.ae/">
              <img src={three0} alt="edge" />
            </a>
            <h5>The Edge Group</h5>
          </div>
        </div>
        <div className={styles.threebox}>
          <div className={styles.content3}>
            <a href="https://trainocate.com/in">
              <img src={three1} alt="trainocate" id={styles.trainocate} />
            </a>
            <h5>Trainocate</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.gac.com/">
              <img src={three2} alt="gac" id={styles.gac} />{" "}
            </a>
            <h5>GAC</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.sbimf.com/">
              <img src={three3} alt="sbimf" id={styles.sbimf} />{" "}
            </a>
            <h5>SBI Mutual Fund</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://blix.in/">
              <img src={three4} alt="blix" id={styles.blix} />
            </a>
            <h5>Blix</h5>
          </div>
          <div className={styles.content3}>
          <a href="https://www.datamatics.com/">
            <img src={three5} alt="datamatics" id={styles.datamatics} /></a>
            <h5>Datamatics</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.qualcomm.com/">
              <img src={three6} alt="qualcomm" id={styles.qualcomm} />
            </a>
            <h5>Qualcomm</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://championsacademy.in/">
              <img src={three7} alt="olympiad" id={styles.olympiad} />
            </a>
            <h5>Olympaid Foundation</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.vedantu.com/">
              <img src={three8} alt="vedantu" id={styles.vedantu} />
            </a>
            <h5>Vedantu</h5>
          </div>
          <div className={styles.content3}>
            <a href="">
              <img src={three9} alt="oab" id={styles.oab} />{" "}
            </a>
            <h5>OAB</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.airbus.com/en">
              <img src={three10} alt="airbus" id={styles.oab} />
            </a>
            <h5>Airbus</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.npci.org.in/">
              <img src={three11} alt="npci" />
            </a>
            <h5>NPCI</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://dhan.co/">
              <img src={three12} alt="dhan" />
            </a>
            <h5>Dhan</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.marshmclennan.com/">
              <img src={four5} alt="mmc" id={styles.oab} />
            </a>
            <h5>MarshMcLennan</h5>
          </div>
          <div className={styles.content3}>
            <a href="https://www.koii.network/">
              <img src={five5} alt="koii" id={styles.oab} />
            </a>
            <h5>Koii.Network</h5>
          </div>
        </div>
      </div>

      {/* four */}
      <div className={styles.four}>
        <div className={styles.header}>
          <h1>International Summit Partners</h1>
          <div className={styles.line}></div>
        </div>
        <div className={styles.fourbox}>
          <div className={styles.content4}>
            <h3>Title Sponsor</h3>
            <a href="https://www.onlinesbi.sbi/">
              <img src={four1} alt="sbi" />
            </a>
            <h5>SBI</h5>
          </div>
          <div className={styles.content4}>
            <h3>Associate Title Sponsor</h3>
            <a href="https://www.fortinet.com/">
              <img src={four2} alt="fortinet" id={styles.fortinet} />
            </a>
            <h5>Fortinet</h5>
          </div>
          <div className={styles.content4}>
            <h3>Spend Management Partner</h3>
            <a href="https://www.zaggle.in/">
              <img src={four3} alt="zaggle" />
            </a>
            <h5>Zaggle</h5>
          </div>
        </div>
      </div>
      <div className={styles.four}>
        <div className={styles.header}>
          <h1>Education Fair Partners</h1>
          <div className={styles.line}></div>
        </div>
        <div className={styles.fourbox}>
          <div className={styles.content4}>
            <h3>Title Sponsor</h3>
            <a href="https://www.onlinesbi.sbi/">
              <img src={four1} alt="sbi" />
            </a>
            <h5>SBI</h5>
          </div>
          <div className={styles.content4}>
            <h3>Associate Title Sponsor</h3>
            <a href="https://www.marshmclennan.com/">
              <img src={four5} alt="mmc" />
            </a>
            <h5>MarshMcLennan</h5>
          </div>
        </div>
      </div>

      {/* five */}
      <div className={styles.five}>
        <div className={styles.header}>
          <h1>General Sponsor</h1>
          <div className={styles.line}></div>
        </div>
        <div className={styles.fivebox}>
          <div className={styles.content5}>
            <h3>Exclusive Music Streaming Partner</h3>
            <a href="https://www.jiosaavn.com/">
              <img src={five1} alt="jiosaavn" id={styles.jiosaavn} />
            </a>
            <h5>Jio Saavn</h5>
          </div>
          <div className={styles.content5}>
            <h3>Official Innovation Partner</h3>
            <a href="https://cadre.com/">
              <img src={five2} alt="cadre" id={styles.cadre} />
            </a>
            <h5>Cadre</h5>
          </div>
          <div className={styles.content5}>
            <h3>Official Ice Cream Partner</h3>
            <a href="https://hangyo.in/">
              <img src={six8} alt="hangyo" id={styles.cadre} />
            </a>
            <h5>Hangyo Ice Cream</h5>
          </div>
          
          <div className={styles.content5}>
            <h3>Official Cybersecurity Partner</h3>
            <a href="https://www.koii.network">
              <img src={five5} alt="koii" id={styles.cadre} />
            </a>
            <h5>Koii.Network</h5>
          </div>
          <div className={styles.content5}>
            <h3>Gifting Partner</h3>
            <a href="https://www.monsterenergy.com/en-in/">
              <img src={five6} alt="" />
            </a>
            <h5>Monster</h5>
          </div>
        </div>
      </div>

      {/* six */}
      <div className={styles.six}>
        <div className={styles.header}>
          <h1>FNB Partners</h1>
          <div className={styles.line0}></div>
        </div>
        <div className={styles.sixbox}>
          <div className={styles.content6}>
            <h3>Offical Energy Drink Partner</h3>
            <a href="https://www.monsterenergy.com/en-in/">
              <img src={six1} alt="monster" />
            </a>
            <h5>Monster</h5>
          </div>
          <div className={styles.content6}>
            <h3>Offical Beverage Partner</h3>
            <a href="https://www.coca-colacompany.com/">
              <img src={six2} alt="cocacola" />
            </a>
            <h5>Coca Cola</h5>
          </div>
          <div className={styles.content6}>
            <h3>Official Milkshake Partner</h3>
            <a href="https://cavinkare.com/">
              <img src={five4} alt="cavins" id={styles.cadre} />
            </a>
            <h5>Cavin's</h5>
          </div>
          <div className={styles.content6}>
            <h3>Official Burger Partner</h3>
            <img src={six3} alt="hunger" />
            <h5>HungerFoodz</h5>
          </div>
          <div className={styles.content6}>
            <h3>Offical Pizza Partner</h3>
            <a href="https://www.ovenstory.in/">
              <img src={six4} alt="oven" />
            </a>
            <h5>Oven Story</h5>
          </div>
          <div className={styles.content6}>
            <h3>Offical Tacos Partner</h3>
            <a href="https://www.newyorkburrito.co.in/">
              <img src={six5} alt="burrito" />
            </a>
            <h5>New York Burrito Company</h5>
          </div>
          <div className={styles.content6}>
            <h3>Official Mexican Partner</h3>
            <a href=""></a>
            <img src={six6} alt="mexibay" />
            <h5>Mexibay</h5>
          </div>
          <div className={styles.content6}>
            <h3>Official Momos Partner</h3>
            <img src={six7} alt="wow" />
            <h5>Wow! Momo</h5>
          </div>
          <div className={styles.content6}>
            <h3>Offical Ice Cream Partner</h3>
            <a href="https://hangyo.in/">
              <img src={six8} alt="hangyo" />
            </a>
            <h5>Hangyo Ice Cream</h5>
          </div>
          <div className={styles.content6}>
            <h3>Official Sandwich Partner</h3>
            <img src={oiii} alt="mygrilled" />
            <h5>My Grilled Sandwich</h5>
          </div>
          <div className={styles.content6}>
            <h3>Official Cold Coffee Partner</h3>
            <a href="https://sleepyowl.co/">
              <img src={six10} alt="Sleep Owl" />
            </a>
            <h5>Sleep Owl</h5>
          </div>
        </div>
      </div>

      {/* seven */}
      <div className={styles.seven}>
        <div className={styles.header}>
          <h1>College Ambassador Program</h1>
          <div className={styles.line}></div>
        </div>
        <div className={styles.sevenbox}>
          <div className={styles.content7}>
            <h3>Official Credit Partner</h3>
            <a href="https://stucred.com/">
              <img src={seven1} alt="stucred" id={styles.stucred} />
            </a>
            <h5>StuCred</h5>
          </div>
          <div className={styles.content7}>
            <h3>Official Travel Partner</h3>
            <a href="https://www.abhibus.com/">
              <img src={seven2} alt="abhibus" id={styles.abhibus} />
            </a>
            <h5>AbhiBus</h5>
          </div>
          <div className={styles.content7}>
            <h3>Official Career Partner</h3>
            <a href="https://www.languify.in/">
              <img src={seven3} alt="languify" id={styles.languify} />
            </a>
            <h5>Languify</h5>
          </div>
          <div className={styles.content7}>
            <h3>Official Coding Partner</h3>
            <a href="https://www.geeksforgeeks.org/">
              <img src={seven4} alt="geeksforgeeks" id={styles.geeksforgeeks} />
            </a>
            <h5>Geeks for Geeks</h5>
          </div>
          <div className={styles.content7}>
            <h3>Social Impact Partner</h3>
            <a href="https://www.joshtalks.com/">
              <img src={seven5} alt="joshtalks" id={styles.joshtalks} />
            </a>
            <h5>JoshTalks</h5>
          </div>
          <div className={styles.content7}>
            <h3>Official Certification Partner</h3>
            <a href="https://www.vskills.in/certification/">
              <img src={seven6} alt="vskills" id={styles.vskills} />
            </a>
            <h5>V Skills</h5>
          </div>
          <div className={styles.content7}>
            {/* <h3>no head</h3> */}
            <a href="https://imarticus.org/">
              <img src={seven7} alt="imarticus" id={styles.imarticus} />
            </a>
            <h5>Imarticus</h5>
          </div>
          <div className={styles.content7}>
            {/* <h3>no head</h3>         */}
            <a href="https://toptrove.org/">
              <img src={seven8} alt="toptrove" id={styles.toptrove} />
            </a>
            <h5>Top Trove</h5>
          </div>
        </div>
      </div>

      {/* eight */}
      <div className={styles.eight}>
        <div className={styles.header}>
          <h1>Media</h1>
          <div className={styles.line0}></div>
        </div>
        <div className={styles.eightbox}>
          <div className={styles.content8}>
            <h3>Media Partner</h3>
            <a href="https://www.freepressjournal.in/">
              <img src={eight1} alt="" />
            </a>
            <h5>Free Press Journal</h5>
          </div>
          <div className={styles.content8}>
            <h3>Media Partner</h3>
            <a href="https://www.indianexpress.com">
              <img src={eight2} alt="" />
            </a>
            <h5>Indian Express</h5>
          </div>
          <div className={styles.content8}>
            <h3>Media Partner</h3>
            <a href="https://www.loksatta.com/">
              <img src={eight3} alt="" />
            </a>
            <h5>Loksatta</h5>
          </div>
          <div className={styles.content8}>
            <h3>Marathi Print Media Partner</h3>
            <a href="https://marathi.indiatimes.com/">
              <img src={eight4} alt="" />
            </a>
            <h5>Maharashtra Times</h5>
          </div>
          <div className={styles.content8}>
            <h3>Digital Media Partner</h3>
            <a href="https://www.myontv.com/">
              <img src={eight5} alt="" />
            </a>
            <h5>ONTV</h5>
          </div>
          <div className={styles.content8}>
            <h3>Multiplex Partner</h3>
            <a href="https://www.pvrcinemas.com/">
              <img src={eight6} alt="" />
            </a>
            <h5>PVR INOX</h5>
          </div>
          <div className={styles.content8}>
            <h3>Magazine Partner</h3>
            <a href="https://theweek.com/">
              <img src={eight7} alt="" />
            </a>
            <h5>The Week</h5>
          </div>
          <div className={styles.content8}>
            <h3>Media Partner</h3>
            <a href="https://cionews.co.in/">
              <img src={eight8} alt="" />
            </a>
            <h5>The Mainstream</h5>
          </div>
          <div className={styles.content8}>
            <h3>PR Partner</h3>
            <a href="https://zexprwire.com/">
              <img src={eight9} alt="" />
            </a>
            <h5>Zex PR Wire</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsor;
