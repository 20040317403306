import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { QRCodeCanvas } from "qrcode.react";
import { UserAuth } from "../../context/AuthContext";
import styles from "./passes.module.css";
import down from "./assets/down.svg";
import logo from "./assets/logo.png";

const Passes = () => {
  const events = [
    {
      id: 0,
      name: "Main Gate Day 0",
      venue: " ",
    },
    {
      id: 1,
      name: "Main Gate Day 1",
      venue: " ",
    },
    {
      id: 2,
      name: "Main Gate Day 2",
      venue: " ",
    },
    {
      id: 3,
      name: "Main Gate Day 3",
      venue: " ",
    },
    {
      id: 4,
      name: "Technoholix",
      venue: "Student Activity Centre (SAC)",
    },
    {
      id: 5,
      name: "Robowars",
      venue: "Student Activity Centre (SAC)",
    },
    {
      id: 6,
      name: "Lectures",
      venue: "Location 3",
    },
    
  ];

  const masterWorkshops = [
    {
      id: 7,
      name: "Workshop 1",
      venue: "Location 1",
    },
    {
      id: 8,
      name: "Workshop 2",
      venue: "Location 2",
    },
    {
      id: 9,
      name: "Workshop 3",
      venue: "Location 3",
    },
  ];

  const masterCompetitions = [
    {
      id: 10,
      name: "Competition 1",
      venue: "Location 1"
    },
    {
      id: 11,
      name: "Competition 2",
      venue: "Location 2"
    }
  ]

  const { user } = UserAuth();
  const [passType, setPassType] = useState("common");
  const [myWorkshops, setMyWorkshops] = useState([]);
  const [myCompetitions, setMyCompetitions] = useState([]);
  const [activeTicket, setActiveTicket] = useState(null);

  const targetEmail = user?.email?.toLowerCase().trim();

  useEffect(() => {
    const checkEmailInCsv = async () => {
      try {
        const fetchCsv = async (filePath) => {
          const response = await fetch(filePath);
          if (!response.ok) throw new Error(`Failed to fetch CSV file: ${filePath}`);
          return response.text();
        };

        const parseCsv = (csvText) =>
          new Promise((resolve, reject) => {
            Papa.parse(csvText, {
              header: true,
              skipEmptyLines: true,
              complete: (result) => resolve(result.data),
              error: reject,
            });
          });

        const [vipText, workaccoText] = await Promise.all([
          fetchCsv("/vip.csv"),
          fetchCsv("/workacco.csv"),
        ]);

        const [vipData, workaccoData] = await Promise.all([
          parseCsv(vipText),
          parseCsv(workaccoText),
        ]);

        const vipEmails = vipData.map((entry) => entry.Email?.toLowerCase().trim());
        const workaccoEmails = workaccoData.map((entry) => entry.Email?.toLowerCase().trim());

        if (vipEmails.includes(targetEmail)) setPassType("vip");
        else if (workaccoEmails.includes(targetEmail)) setPassType("workacco");
      } catch (error) {
        console.error("Error processing CSV files:", error);
      }
    };

    if (targetEmail) checkEmailInCsv();
  }, [targetEmail]);

  const loadCSV = async (path) => {
    try {
      const response = await fetch(path);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV file: ${response.statusText}`);
      }

      const csvText = await response.text();
      const rows = csvText.trim().split("\n");
      const headers = rows[0].split(",");

      const data = rows.slice(1).map((row) => {
        const values = row.split(",");
        return headers.reduce((acc, header, index) => {
          acc[header.trim()] = values[index]?.trim();
          return acc;
        }, {});
      });

      return data;
    } catch (error) {
      console.error("Error loading CSV:", error);
      return [];
    }
  };

  const findWorkshops = async (email) => {
    const csvData = await loadCSV("/workshop.csv");
    const workshops = csvData
      .filter((entry) => entry.email === email)
      .map((entry) => entry.workshop);
    return workshops;
  };
  
  const findCompetitions = async (email) => {
    const csvData = await loadCSV("/competitions.csv");
    const competitions = csvData
      .filter((entry) => entry.email === email)
      .map((entry) => entry.competition);
    return competitions;
  };

  useEffect(() => {
    const fetchCompetitions = async () => {
      if (!targetEmail) return;
      try {
        const fetchedCompetitions = await findCompetitions(targetEmail);
        // setWorkshops(fetchedWorkshops);

        // Map fetched workshops to masterWorkshops
        const userCompetitions = fetchedCompetitions.map((competitionsName) =>
          masterCompetitions.find((myCompetition) => myCompetition.name === competitionsName)
        ).filter(Boolean); // Exclude undefined matches
        setMyCompetitions(userCompetitions);
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    fetchCompetitions();

    const fetchWorkshops = async () => {
      if (!targetEmail) return;
      try {
        const fetchedWorkshops = await findWorkshops(targetEmail);
        // setWorkshops(fetchedWorkshops);

        // Map fetched workshops to masterWorkshops
        const userWorkshops = fetchedWorkshops.map((workshopName) =>
          masterWorkshops.find((myWorkshop) => myWorkshop.name === workshopName)
        ).filter(Boolean); // Exclude undefined matches
        setMyWorkshops(userWorkshops);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    };

    fetchWorkshops();

    
  }, [targetEmail, masterCompetitions]);


  const handleTicketClick = (ticketId) => {
    setActiveTicket((prev) => (prev === ticketId ? null : ticketId));
  };

  const generateQrData = (eventName) => {
    return `Email: ${targetEmail}\nPass Type: ${passType}\nEvent: ${eventName}`;
  };

  return (
    <div className={styles.passpage}>
  <div className={styles.container}>
    {events.map((ticket) => (
      <div
        key={ticket.id}
        className={`${styles.ticketcontainer} ${
          passType === "vip" ? styles.vipContainer : ""
        }`}
      >
        <div
          className={styles.ticket}
          onClick={() => handleTicketClick(ticket.id)}
        >
          <p className={styles.name}>{ticket.name}</p>
          <img src={down} className={styles.arrow} alt="arrow" />
        </div>
        <div
          className={`${styles.pass} ${
            activeTicket === ticket.id ? styles.slideout : ""
          } ${passType === "vip" ? styles.vipPass : ""}`}
        >
          <div className={styles.qrcontainer}>
            {passType !== "vip" && (
              <div className={styles.qrCode}>
                <QRCodeCanvas
                  value={generateQrData(ticket.name)}
                  size={150}
                  bgColor="#ffffff"
                  fgColor="#000000"
                />
              </div>
            )}
          </div>
          <div className={styles.infocontainer}>
            <img src={logo} alt="Event Logo" />
            <p className={styles.ticketname}>{ticket.name.toUpperCase()}</p>
            <h1 className={styles.passType}>
              {passType === "vip"
                ? "VIP Pass"
                : passType === "workacco"
                ? "Premium Pass"
                : "Common Pass"}
            </h1>
            <p className={styles.passtext}>Email: {targetEmail}</p>
            <p className={styles.passtext}>Venue: {ticket.venue}</p>
          </div>
        </div>
      </div>
    ))}
    

        {myWorkshops.map((workshop) => (
          <div key={workshop.id} className={styles.ticketcontainer}>
            <div
              className={styles.ticket}
              onClick={() => handleTicketClick(workshop.id)}
            >
              <p className={styles.name}>{workshop.name}</p>
              <img src={down} className={styles.arrow} alt="arrow" />
            </div>
            <div
              className={`${styles.pass} ${
                activeTicket === workshop.id ? styles.slideout : ""
              }`}
            >
              <div className={styles.qrcontainer}>
                <div className={styles.qrCode}>
                  <QRCodeCanvas
                    value={generateQrData(workshop.name)}
                    size={150}
                    bgColor="#ffffff"
                    fgColor="#000000"
                  />
                </div>
              </div>
              <div className={styles.infocontainer}>
                <img src={logo}></img>
                <p className={styles.ticketname}>{workshop.name.toUpperCase()}</p>
                <h1 className={styles.passType}>
                  Workshop Pass
                </h1>
                <p className={styles.passtext}>Email: {targetEmail}</p>
                <p className={styles.passtext}>Venue: {workshop.venue}</p>
              </div>
            </div>
          </div>
        ))}
        {myCompetitions.map((competition) => (
          <div key={competition.id} className={styles.ticketcontainer}>
            <div
              className={styles.ticket}
              onClick={() => handleTicketClick(competition.id)}
            >
              <p className={styles.name}>{competition.name}</p>
              <img src={down} className={styles.arrow} alt="arrow" />
            </div>
            <div
              className={`${styles.pass} ${
                activeTicket === competition.id ? styles.slideout : ""
              }`}
            >
              <div className={styles.qrcontainer}>
                <div className={styles.qrCode}>
                  <QRCodeCanvas
                    value={generateQrData(competition.name)}
                    size={150}
                    bgColor="#ffffff"
                    fgColor="#000000"
                  />
                </div>
              </div>
              <div className={styles.infocontainer}>
                <img src={logo}></img>
                <p className={styles.ticketname}>{competition.name.toUpperCase()}</p>
                <h1 className={styles.passType}>
                  Competition Pass
                </h1>
                <p className={styles.passtext}>Email: {targetEmail}</p>
                <p className={styles.passtext}>Venue: {competition.venue}</p>
              </div>
            </div>
          </div>
        ))}
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </div>
  );
};

export default Passes;
