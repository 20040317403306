import styles from './merchCard.module.css';
import React from 'react';

const MerchCard = ({image, link, oldprice, saleprice}) => {

    const redirect = (link) => {
        window.open(link, "_blank");
        // console.log("Lci");
    };

    return(
        <>
        <div className={styles.merchCard}>
            <img src={image} alt='cloth image' className={styles.Cardimg}></img>
            <div className={styles.overlay}>
                <div className={styles.Pricetext}>
                    <span className={styles.saleprice}>INR {saleprice}</span>
                    <span className={styles.oldPrice}>{oldprice}</span>
                    {/* <span>{saleprice}</span> */}
                </div>
                <div className={styles.line}></div>
                <div className={styles.btn} onClick={() => {redirect(link)}}>Check it Out</div>
            </div>
        </div>
        </>
    )
}

export default MerchCard;