import React, { useState, useEffect } from 'react';
import './smp.css';
import bg from './assets/Exhi_back.jpeg';
import abhishek from './assets/Abhishek.jpg'
import apoorva from './assets/Apoorva.png'
import dhairya from './assets/Dhairya.png'
import atharva from './assets/Atharva.png'
import prashi from './assets/Prashi.png'
import PP from './assets/PreviousPartners.png'
import SMP from './assets/smp.png'
import tt from './assets/trakintech.png'
import iqlipse from './assets/iqlipsenova.png'
import nikhil from './assets/mumbikernikhil.png'
import burner from './assets/techburner.png'


const sponsorLogos1 = [
    { name: "Abhishek Mishra",img: abhishek },
    { name: "Apoorva Agarwal", img: apoorva },
    { name: "Dhairya Gangwani ", img: dhairya },
    { name: "Atharva Patil", img: atharva },
    { name: "Prashi Jain ", img: prashi },
    { name: "Sponsor 6", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 7", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 8", img: "https://via.placeholder.com/150" },
];

const sponsorLogos2 = [
    { name: "Trakin Tech",img: tt },
    { name: "Iqlipse Nova", img: iqlipse },
    { name: "Mumbiker Nikhil", img: nikhil },
    { name: "Tech Burner", img: burner },
    { name: "Sponsor 5", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 6", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 7", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 8", img: "https://via.placeholder.com/150" },
];

const Smp = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        // width: "100vw",
        // minHeight: "100vh",
        // position: "absolute",
        // top: "0",
        // zIndex: "-1",
        // backgroundAttachment: isMobile ? "scroll" : "fixed", /* Conditional scroll effect */
    };

    return (
        <div>
            {/* Background */}
            <div style={bgHaiJi}>

            

            <div>
            <img src={SMP} className="smp"/>
            </div>

            {/* Sponsor Section */}
            <section className="sponsor-section">
                {/* <h1 className="sponsor-title">Our Social Media Partners</h1> */}
                <div className="sponsor-grid">
                    {sponsorLogos1.map((sponsor, index) => (
                        <a href={sponsor.url} key={index} className="sponsor-item">
                            <img src={sponsor.img} alt={sponsor.name} className="sponsor-logo" />
                            <p>{sponsor.name}</p>
                        </a>
                    ))}
                </div>
            </section>

            <div>
            <img src={PP} className="pp"/>
            </div>

            <section className="sponsor-section">
                {/* <h1 className="sponsor-title">Our Social Media Partners</h1> */}
                <div className="sponsor-grid">
                    {sponsorLogos2.map((sponsor, index) => (
                        <a href={sponsor.url} key={index} className="sponsor-item">
                            <img src={sponsor.img} alt={sponsor.name} className="sponsor-logo" />
                            <p>{sponsor.name}</p>
                        </a>
                    ))}
                </div>
            </section>

            {/* <div className= "Contactbox">
            <div className="Contactbox1">
              Divyansh Ranjan<br></br>
              ranjan@techfest.org<br></br> */}
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              {/* <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {8318806303}
              </div>
            </div>
            </div> */}
            </div>
        </div>
    );
};

export default Smp;
