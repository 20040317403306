import React, { useState } from 'react'
import styles from './merch.module.css'
import MerchCard from './merchCard'
import BS from './assets/BlackShirtGIF2.gif'
// import BSM from './assets/BlackShirtMan 1.png'
import WS from './assets/WS_new2.jpeg'
import hoodie from './assets/Hoodie_new.jpeg'
// import hoodieMan from './assets/HoodieMan 1.png'
import bg from './assets/hexabg.jpg'

const merch = () => {

    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor:'white',
        backgroundPosition: "center center",
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
      };
    
    // const arr = [BS, BSM, hoodie, hoodieMan, WS];
    const arr = [
        {
            photo: BS,
            link: "https://tshirtsnearme.in/product/techfest24-iit-bombay-black-t-shirt-unisex/",
            oldprice: "479",
            saleprice: "299"
        },

        {
            photo: WS,
            link: "https://tshirtsnearme.in/product/techfest24-iit-bombay-white-t-shirt-unisex/",
            oldprice: "479",
            saleprice: "299"
        },
        
        // {
        //     photo: BSM,
        //     link: "https://tshirtsnearme.in/product/techfest24-iit-bombay-black-t-shirt-unisex/",
        //     oldprice: "479",
        //     saleprice: "299"
        // },

        {
            photo: hoodie,
            link: 'https://tshirtsnearme.in/product/techfest24-iit-bombay-navy-blue-hoodie-with-custom-name/',
            oldprice: "799",
            saleprice: "599" 
        },

        // {
        //     photo: hoodieMan,
        //     link: "https://tshirtsnearme.in/product/techfest24-iit-bombay-navy-blue-hoodie-with-custom-name/",
        //     oldprice: "799",
        //     saleprice: "599" 
        // },

        // {
        //     photo: WS,
        //     link: "https://tshirtsnearme.in/product/techfest24-iit-bombay-white-t-shirt-unisex/",
        //     oldprice: "479",
        //     saleprice: "299"
        // }

    ]

    return(
        <div style={bgHaiJi}>
        <div className={styles.merch}>
           <div className={styles.title}>STORE</div>
           <div className={styles.merchBox}>
             {
                arr.map((obj, index) => (
                    <MerchCard key={index} image={obj.photo} link={obj.link} oldprice={obj.oldprice} saleprice={obj.saleprice}/>
                ))
             }
           </div>
           {/* <div className={styles.contactUs}>
             <div className={styles.Mtitle}>Contact</div>
             <div className={styles.Name}>Jay Choudhary</div>
             <div className={styles.no}>+91 9752013987</div>
           </div> */}

        </div>
        </div>
    )
}

export default merch