import React, { useState } from 'react';
import styles from './compi_data.module.css';
import Faq from 'react-faq-component';
import './x.css';
import Whatsapplink from '../../../../components/whatsapp/link';

const CrackTheCase = () => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (openX) => {
        if(openX === open){
            setOpen(false);
        }
        else{
            setOpen(openX);
        }
    }

    const [openFaq, setOpenFaq] = useState(false);

    const OpenFaq = (index) => {
        setOpenFaq(prevOpenFaq => prevOpenFaq === index ? null : index);
    }


    const data = {
        rows: [
          {
            title: "Why should I participate in SBI Crack the Case?",
            content: "The competition provides the participants with real life problem solving. In the final round, participants work together to solve the problems in a specific time."
          },
          {
            title: "How many stages does the competition have?",
            content: "The competition is divided into 3 stages, detailed information about both stages can be found in the problem statement."
          },
          {
            title: "How to register?",
            content: "Participants should be Signed in before Registering.Then, follow these steps for registration :www.techfest.org -> Competitions > ClassiFi -> Register -> Fill all your details -> You are registered in that competition. Now you must either Create a team or Join a Team using the registered mail ID."
          },
          {
            title: "What is the Registered Mail Id of a person?",
            content: "The Gmail Id which was used to Sign In is the registered mail Id. It is a unique identity of any participant. And will be used in adding team members to a team, joining a pre-existing team, availing accommodation, etc."
          },
          {
            title: "Is it free to register for SBI Crack the Case?",
            content: "Yes, it is free to register."
          },
          {
            title: "How can I make my own team (as a team leader)?",
            content: "Follow this process: www.techfest.org -> (hover on) Competitions -> Competitions -> ClassiFi -> Explore -> Register -> Fill all your details -> Team -> Create a team -> Use the Registered Mail ID of other participants to add them to your team. They must be already registered before adding them to your team"
          },
          {
            title: "How many people can be there in one team?",
            content: "One team can have maximum of 4 members."
          },
          {
            title: "Who can add members in a team?",
            content: "Only the team leader has the right to add members in a team. However, others can join an existing team by clicking on the Join Team button."
          },
          {
            title: "Can I add myself to an existing team?",
            content: "Yes, follow these steps to get added to a team. Join team -> Enter Team-Id. You will be added to that team."
          },
          {
            title: "How can I remove members from my team (as a team leader)?",
            content: "You cannot remove a team member once added, you can dissolve the team and form a new one."
          },
          {
            title: "What happens when the team leader leaves their team?",
            content: "If the team leader leaves their team, the team will get dissolved. The other members of the team will then have to create a new team."
          },
          {
            title: "Is it necessary for the team leader to add all the team members at once?",
            content: "No, it is not necessary. The team leader can add one member or none, only once, which is while creating the team, but if the team is not full and they want to add more members afterwards, then members (to be added) will have to use the Join Team button to join the team."
          },
          {
            title: "How can a team member (other than the leader) leave the team?",
            content: "Click on the Leave Team button and you will be removed from the registered team."
          },
          {
            title: "Who can delete a team?",
            content: "Only the team leader can delete the team using the Dissolve Team button."
          },
          {
            title: "Can a team have members from different colleges?",
            content: "Yes, students from different colleges can form a team."
          },
          {
            title: "Can we change the team leader of our team?",
            content: "No, you can’t change the team leader of your team. But, you can dissolve the team and form a new one."
          },
          {
            title: "Can I register for more than one competition?",
            content: "Yes, you can participate in more than one competition. However, it is recommended to focus on only one competition as there may be some chances of slot clash."
          },
          {
            title: "How many teams can I join?",
            content: "You can join only one team in one competition. Participating in more than one team will get you DISQUALIFIED."
          },
        ],
      };

    const styles1 = {
        bgColor: 'none',
        titleTextColor: '#fff',
        rowTitleColor: '#fff',
        arrowColor: '#fff',
        rowContentColor: '#fff',
        marginBottom: '20px',
        maxWidth: '80%',
        margin: 'auto',
        rowContentMarginBottom: '0',
      }
      const walink1 = "https://chat.whatsapp.com/Fa5budYLNviDK9wy85cRRo";
      const dslink1 = "https://chat.whatsapp.com/Fa5budYLNviDK9wy85cRRo";
    return (
        <div className={styles.mWholeData}>
        <div className={styles.grouplink}>
        <div className={styles.wabutton }><Whatsapplink walink="https://chat.whatsapp.com/JM03PGu1jxGHfShYlZE3f4" dslink="https://chat.whatsapp.com/JM03PGu1jxGHfShYlZE3f4" target="_blank"/></div>
        </div>  
            <div>
                <div onClick={() => handleClickOpen('About')} className={`${styles.whatu} ${open === 'About' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatu_rect2}`}>About</div>
                </div>
                <div className={`${styles.whatd} ${open === 'About' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>Analyze and identify the root causes of declining growth for an SBI branch, and propose
innovative strategies to boost customer engagement, enhance profitability, and optimize
financial operations.</div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Structure')} className={`${styles.whatu} ${open === 'Structure' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.str_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.str_rect2} ${styles.whatu_rect2}`}>Structure</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Structure' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    <ul>
                <li>
                  Round 1: <br />
                  Participants must submit an abstract outlining their analysis and proposed solutions to the challenges described in the problem statement. After the submission, a mentoring session with experts will help refine the strategies.
                </li>
                <hr />
                <li>
                  Round 2: <br />
                  During this phase, participants will consult with banking experts to gain deeper insights into the problem. They will use the mentorship to address gaps in their solutions and prepare for final evaluation.
                </li>
                <hr />
                <li>
                  Final Round: <br />
                  Selected participants will present their solutions offline at IIT Bombay, showcasing detailed analysis, proposed strategies, and implementation plans.
                </li>
              </ul>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Timeline')} className={`${styles.whatu} ${open === 'Timeline' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.time_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.time_rect2} ${styles.whatu_rect2}`}>Timeline</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Timeline' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    <li>
      Registration Deadline:
      <br />
        December 14, 2024
    </li>
    <hr />
    <li>
      Round 1 Submission:
      <br />
      December 15, 2024
    </li>
    <hr />
    <li>
        Round 1 Result Announcement:
        <br/>
        December 16, 2024
    </li>
    <hr/>
    <li>
      Round 2 - Mentorship:
      <br />
      December 21, 2024 - January 18, 2025
    </li>
    <hr />
    <li>
        Final Round at IIT Bombay (Offline): 
        <br/>
        January 24, 2025
    </li>

                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('FAQs')} className={`${styles.whatu} ${open === 'FAQs' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.faq_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.faq_rect2} ${styles.whatu_rect2}`}>FAQs</div>
                </div>
                <div className={`${styles.whatd} ${open === 'FAQs' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                        <Faq data={data} styles={styles1}/>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Rules')} className={`${styles.whatu} ${open === 'Rules' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.rule_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.rule_rect2} ${styles.whatu_rect2}`}>Rules</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Rules' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    1.Teams must register online through the Techfest website
            <br/>2.. A unique Team ID will be provided upon registration for further references
            <br/>3. The decision of the organizers and judges will be final and binding.
            <br/>4. Late, lost, or misdirected submissions will not be the responsibility of the organizers.
            <br/>5. Official communication will take place via email; participants must monitor their
            inbox.
            <br/>6. Participants should ensure the originality of their ideas; plagiarism will lead to
disqualification.
            <br/>7. Teams are encouraged to leverage quantitative analysis and real-world business
            models for their solutions.
            <br/>8. The organizers reserve the right to modify rules, which will be communicated via
            email and updated on the website.
            <br/>9. The organisers reserve the right to change any or all of the above rules as they deem fit. Change in rules, if any, will be highlighted on the website and notified to the registered teams
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Contact')} className={`${styles.whatu} ${open === 'Contact' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.ctc_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.ctc_rect2} ${styles.whatu_rect2}`}>Contact</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Contact' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    Naitik (Team Techfest)<br/>
                    <div className="col-3 contactMail" onClick={() => window.location.href = `mailto:naitik.techfest@gmail.com`}>naitik.techfest@gmail.com</div>
                    <div className="col-3 contactNum" onClick={() => window.location.href = `tel:9905610567`}>+91 99056 10567</div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default CrackTheCase;