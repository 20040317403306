import React, { useState } from 'react'
import styles from './Bgmi.module.css'
import bgmi_land from './assets/bgmi_land3.jpg'
import bgVideo from './assets/sparksBg2.mp4'
import helmet from './assets/Helmet.jpg'
import PrizePool from './prizepool'



const Bgmi = () => {

    const [active, setActive] = useState('tab-1');

    const handleClick = (event) => {
        setActive(event.target.id);
    };

    const handleRegisterClick = () => {
        window.open("https://forms.gle/rUuZah3WCUH1nmSaA", "_blank");
    };

    return (
        <div className={styles.bgmi}>
            <video className={styles.bgVideo} autoPlay loop muted>
                <source src={bgVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className={styles.hero}>
                <img src={bgmi_land} alt="bgmi_land" className={styles.bgmi_land} />
                <div className={styles.head}>Battlegrounds Mobile India</div>
                <div className={styles.brief}>A popular multiplayer battle royale game where players compete to be the last one standing on a shrinking battlefield, combining strategy, survival, and combat skills</div>
            </div>

            {/* <div className={styles.sponsor}>
                <div className={styles.sponsor_head}>Sponsors</div>
                <div className={styles.sponsor_images}>
                    <div className={styles.image} id='spon1'>
                        <img src={helmet} alt="" />
                    </div>
                    <div className={styles.image} id='spon2'>
                        <img src={helmet} alt="" />
                    </div>
                </div>
            </div> */}

            <div className={styles.prizepool}>
                <div className={styles.prizepool_head}>Prize Pool</div>
                <PrizePool prize={"80k"} parti={"1000+"} color={1} />
            </div>

            {/* <div className={styles.reg}>
                <div className={styles.regbtn} onClick={handleRegisterClick}>REGISTER</div>
            </div> */}

            <div className={styles.box}>
              <div className={styles.tabs}>
              <div className={`${styles.tabButton} ${active === 'tab-1' ? styles.active : ''}`} id="tab-1" onClick={handleClick}>
                  Details
              </div>
              <div className={`${styles.tabButton} ${active === 'tab-2' ? styles.active : ''}`} id="tab-2" onClick={handleClick}>
                Timeline
              </div>
              <div className={`${styles.tabButton} ${active === 'tab-3' ? styles.active : ''}`} id="tab-3" onClick={handleClick}>
               General Rules
              </div>
              <div className={`${styles.tabButton} ${active === 'tab-4' ? styles.active : ''}`} id="tab-4" onClick={handleClick}>
               Contact Us
              </div>
             </div>
             <div className={styles.tabContent}>
                  <div className={`${styles.tabPage}  ${active === 'tab-1' ? styles.active : ''}`}>
                     <ul className={styles.Details}>
                        <li>Stand a chance to win prizes worth INR 1 Lakh</li>
                        <li>Top 8-10 Teams invited at Techfest, IIT Bombay for finals</li>
                        <li>Eligibility: Open and Free for all</li>
                     </ul>
                 </div>
                 <div className={`${styles.tabPage}  ${active === 'tab-2' ? styles.active : ''}`}>
                    <ul className={styles.Timeline}>
                        <li>Registration Closes by 25th November</li>
                          <li>  Brackets and Schedule Release - 27th November </li>
                           <li> Online Qualifiers will start by 1st December</li>
                           <li>  Final Playoffs - 17th-18th December</li>
                     </ul>
                </div>
                <div className={`${styles.tabPage}  ${active === 'tab-3' ? styles.active : ''}`}>
                <div className={styles.Genrules}>
  <ul className={styles.Timeline}>
    <li>If any player of the team gets caught using cheats or exploiting bugs or glitches, they will be disqualified on the spot.</li>
    <li>Any type of toxicity won’t be allowed. Two initial warnings will be given if found, and then a penalty or disqualification will be imposed.</li>
    <li>Single substitute will be allowed.</li>
    <li>You will be informed about the matches a day prior to your match.</li>
    <li>Management will wait for the teams for 15 minutes for the players to join. Otherwise, they will have the choice to not play or play with the players available at that moment.</li>
    <li>Final result pictures and POVs should be captured by the team and should be sent to the management after the match ends [for online preliminaries].</li>
    <li>If a team is non-resident in Mumbai, paid accommodation will be provided.</li>
    <li>Authorities have the power to make the final decisions, and teams should respect and follow that.</li>
  </ul>
</div>
                </div>
                <div className={`${styles.tabPage}  ${active === 'tab-4' ? styles.active : ''}`}>
                     <div className={styles.managers}>
                         <div className={styles.hardik}>
                             <div>Hardik Gupta</div>
                             <div>+91 62604 47196</div>
                             <div>hardik@techfest.org</div>

                            </div>
                            <div className={styles.ankit}>
                                <div>Ankit Raj</div>
                                <div>+91 70616 82606</div>
                                <div>ankit@techfest.org</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Bgmi
