import React, { useEffect, useRef } from 'react';
import styles from './history.module.css';
import bg from './assets/grid.png';
import timeline from './assets/historykajhund.png';
import upper from './assets/higher.png';
import lower from './assets/lower.png';
import photo from './assets/photo.png';
import history from './assets/History.svg';
import photo1 from './assets/2023(1).png'
import photo2 from './assets/2023(2).png'
import photo3 from './assets/22 (1).png'
import photo4 from './assets/21 (1).png'
import photo5 from './assets/21 (3).png'
import photo6 from './assets/20 (1).png'
import photo7 from './assets/20 (2).png'
import photo8 from './assets/19 (2).png'
import photo11 from './assets/l9[1] (1).png'
import photo10 from './assets/l9[1] (2).png'
import photo9 from './assets/l9[1].png'
import photo12 from './assets/18[1].png'
import photo13 from './assets/18 (1).png'
import photo14 from './assets/17 (1).png'
import photo15 from './assets/16 (1).png'
import photo16 from './assets/15(1).png'



const History = () => {
    const card1Ref = useRef(null);
    const card2Ref = useRef(null);
    const card3Ref = useRef(null);
    const card4Ref = useRef(null);
    const card5Ref = useRef(null);
    const card6Ref = useRef(null);
    const card7Ref = useRef(null);
    const card8Ref = useRef(null);
    const card9Ref = useRef(null);
    const card10Ref = useRef(null);
    const card11Ref = useRef(null);
    const card12Ref = useRef(null);
    const card13Ref = useRef(null);
    const card14Ref = useRef(null);
    const card15Ref = useRef(null);
    const card16Ref = useRef(null);
    let ticking = false; // To prevent multiple `requestAnimationFrame` calls

    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
    };

    useEffect(() => {
        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    revealCards();
                    ticking = false;
                });
                ticking = true;
            }
        };

        const revealCards = () => {
            const revealCard = (card) => {
                if (!card) return;
                const rect = card.getBoundingClientRect();
                const isVisible = rect.top < window.innerHeight && rect.bottom > 0;
                const screenWidth = window.innerWidth;
                const isMobile = screenWidth <= 430;

                if (isVisible) {
                    setTimeout(() => {
                        const upTranslate = isMobile ? '-35px' : (screenWidth <= 768 ? '-35px' : '-95px');
                        const downTranslate = isMobile ? '20px' : (screenWidth <= 768 ? '30px' : '70px');

                        card.querySelector(`.${styles.up}`).style.transform = `translateY(${upTranslate})`;
                        card.querySelector(`.${styles.down}`).style.transform = `translateY(${downTranslate})`;
                        card.querySelector(`.${styles.photo}`).style.opacity = '1';
                    }, 0);
                } else {
                    card.querySelector(`.${styles.up}`).style.transform = 'translateY(0)';
                    card.querySelector(`.${styles.down}`).style.transform = 'translateY(0)';
                    card.querySelector(`.${styles.photo}`).style.opacity = '0'; // Reset opacity to 0
                }
            };

            revealCard(card1Ref.current);
            revealCard(card2Ref.current);
            revealCard(card3Ref.current);
            revealCard(card4Ref.current);
            revealCard(card5Ref.current);
            revealCard(card6Ref.current);
            revealCard(card7Ref.current);
            revealCard(card8Ref.current);
            revealCard(card9Ref.current);
            revealCard(card10Ref.current);
            revealCard(card11Ref.current);
            revealCard(card12Ref.current);
            revealCard(card13Ref.current);
            revealCard(card14Ref.current);
            revealCard(card15Ref.current);
            revealCard(card16Ref.current);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={styles.history} style={bgHaiJi}>
            <div className={styles.timeline}>
                <div className={styles.HistoryText}>
                    <img src={history} alt='history'></img>
                </div>
                <img src={timeline} className={styles.timeimg} alt="Timeline" />
                <div className={styles.card1} ref={card1Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo1} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card2} ref={card2Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo2} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card3} ref={card3Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo16} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card4} ref={card4Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo4} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card5} ref={card5Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo5} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card6} ref={card6Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo12} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card7} ref={card7Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo7} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card8} ref={card8Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo8} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card9} ref={card9Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo11} className={styles.photo} alt="Photo" />
                </div>
                {/* <div className={styles.card10} ref={card10Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo10} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card11} ref={card11Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo11} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card12} ref={card12Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo12} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card13} ref={card13Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo13} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card14} ref={card14Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo14} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card15} ref={card15Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo15} className={styles.photo} alt="Photo" />
                </div>
                <div className={styles.card16} ref={card16Ref}>
                    <img src={upper} className={styles.up} alt="Upper part" />
                    <img src={lower} className={styles.down} alt="Lower part" />
                    <img src={photo16} className={styles.photo} alt="Photo" />
                </div> */}
            </div>
        </div>
    );
}

export default History;
