import React, { useState, useEffect } from 'react';
import './Countdown.css'; // Import CSS for styling

const Countdown = ({ deadline }) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const timeDifference = deadline - now;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [time, setTime] = useState(calculateTimeRemaining);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(calculateTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (unit) => String(unit).padStart(2, '0');

  return (
    <div className="countdown-container">
      <h1 style={{fontSize:'28px'}}>Registrations are closed! Selections will be announced soon. Incase you missed the deadline contact below mentioned contact.</h1>
      {/* <div className="time-display">
        <div className="time-unit">
          <span>{formatTime(time.days)}</span>
          <p>Days</p>
        </div>
        <div className="time-unit">
          <span>{formatTime(time.hours)}</span>
          <p>Hours</p>
        </div>
        <div className="time-unit">
          <span>{formatTime(time.minutes)}</span>
          <p>Minutes</p>
        </div>
        <div className="time-unit">
          <span>{formatTime(time.seconds)}</span>
          <p>Seconds</p>
        </div>
      </div> */}
    </div>
  );
};

export default Countdown;