import React, { useEffect, useRef } from 'react'
import styles from './Esports.module.css'
// import bg from '../../assets/bg/bg.jpg';
import bg from './assets/bg.jpg'
import bgmiValo from './assets/BgValo_test.jpg'
import Title from './assets/Title.png'
import Card from './assets/cardlec4.jpg'
import bgmiCard from './assets/Exhi_card (1).jpg'
import ValoCard from './assets/Exhi_card (2).jpg'
import PrizePool from './prizepool';
import Helmet_img from './assets/Helmet-small.png'
import hero from './assets/hero.png';
import spike from './assets/Spike.png';
import { useNavigate } from 'react-router-dom'

const Esports = () => {

    const cardRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.appear);
          }
        });
      },
      { threshold: 0.1 }
    );

    cardRef.current.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

//   about us
const aboutTextRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.appearFromLeft);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (aboutTextRef.current) observer.observe(aboutTextRef.current);

    return () => {
      if (aboutTextRef.current) observer.unobserve(aboutTextRef.current);
    };
  }, []);



    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        // backgroundColor:'#e3e3ed',
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
    };

    const navigate = useNavigate();

    const handleExploreClick = () => {
        navigate('/bgmi');
    };

    const handleExploreClick2 = () => {
        navigate('/valorant');
    };

    const handleRegisterClick = () => {
        window.open("https://forms.gle/rUuZah3WCUH1nmSaA", "_blank");
    };
    
    const handleRegisterClick2 = () => {
        window.open("https://forms.gle/eKjdGYLKfWqHV98p6", "_blank");
    };

    return (
        <div>
            <div className={styles.Esports} style={bgHaiJi}>
                {/* <div className={styles.Headerimgbox}>
                    <img className={styles.HeaderText} src={Title} alt='Title 1'></img>
                    <img src={bgmiValo} className={styles.bgmiValoimg} alt='Image'></img>
                </div> */}2
                <div className={styles.herobox}>
                    <img className={styles.hero} src={hero} alt="" />
                </div>
                <div className={styles.about}>
                    <div className={styles.aboutHeader}>ABOUT</div>
                    <div className={`${styles.aboutText}`} ref={aboutTextRef}>
                    Techfest 2024 is excited to bring you one of the biggest college esports events in India! This year, we're hosting an epic Techfest Esports Tournament, featuring the widely popular game BGMI and Valorant. With massive prizes and prestige at stake, the competition is sure to draw the best gaming talent from across the country.
The tournament will kick off with online qualifiers, culminating in an intense LAN finale at Techfest, INDIAN INSTITUTE OF TECHNOLOIGY-BOMBAY, from 17th-19th December 2024. Whether you're a seasoned professional or an aspiring amateur, this is your chance to showcase your skills and compete with the best.
Registrations are free and open to all, giving everyone a fair shot at glory! Don't miss out on this incredible opportunity—register today and get ready to battle it out for esports supremacy at Techfest 2024.
                    </div>
                </div>


                <div className={styles.cardbox}>
      <div className={`${styles.card}`} ref={(el) => (cardRef.current[0] = el)}>
        <img src={bgmiCard} alt='' className={styles.cardimage} />
        <div className={styles.cardoverlay}>
          <img src={Helmet_img} className={styles.Helmetimg} alt='img' />
          <div className={styles.cardText}>BGMI</div>
          <div className={styles.bgmibtn}>
            <div className={styles.btnExp} onClick={handleExploreClick}>Explore</div>
            {/* <div className={styles.btnReg} onClick={handleRegisterClick}>Register</div> */}
          </div>
        </div>
      </div>

      <div className={`${styles.card}`} ref={(el) => (cardRef.current[1] = el)}>
        <img src={ValoCard} alt='' className={styles.cardimage} />
        <div className={styles.cardoverlay}>
          <img src={spike} className={styles.spikeimg} alt='' />
          <div className={styles.cardText}>Valorant</div>
          <div className={styles.bgmibtn}>
            <div className={styles.btnExp} onClick={handleExploreClick2}>Explore</div>
            {/* <div className={styles.btnReg} onClick={handleRegisterClick2}>Register</div> */}
          </div>
        </div>
      </div>
    </div>



                <div className={styles.prizepool}>
                    <PrizePool prize={"1.5L+"} parti={"1000+"} color={1} />
                </div>

                <div className={styles.contactus}>
                    {/* <h2>CONTACT US</h2> */}
                    <div className={styles.contactbox}>
                            <p>Hardik Gupta <br />
                            hardik@techfest.org <br />
                            +91 62604 47196</p>
                        </div>
                        <div className={styles.contactbox}>
                            <p>Ankit Raj <br />
                            ankit@techfest.org <br />
                            +91 70616 82606</p>
                        </div>
                    <div className={styles.container}>
                        
                    </div>
                </div>

            </div>
        </div>

    )


}

export default Esports